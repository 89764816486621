@media (max-width: 767px) {
  body {
    font-family: "Open Sans", sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    line-height: 1.500em !important;
    color: #444444 !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    -ms-font-smoothing: antialiased !important;
    background-color: white !important;
  }

  .modal-container.bsizeSmall {
    margin-left: 40px;
  }

  strong {
    font-weight: 600;
    color: #222222;
  }
  em {
    font-weight: 400;
    font-style: italic;
    color: #222222;
  }
  a:link,
  a:visited {
    font-weight: 600;
    color: #f96302;
    text-decoration: none;
  }
  a:hover,
  .stepper a.step:active {
    text-decoration: underline;
  }
  h1 {
    font-weight: 300;
    font-size: 3em;
    letter-spacing: -0.01em;
    line-height: 1.111em;
    /*50px*/
  }
  h2 {
    font-weight: 600 !important;
    font-size: 1.5em !important;
    letter-spacing: -0.010em !important;
    line-height: 1.250em !important;
    margin-top: 20em !important;
    /*30px*/
  }
  h3 {
    font-weight: 600 !important;
    font-size: 1.125em;
    letter-spacing: -0.010em !important;
    line-height: 1.333em;
    /*24px*/
  }
  h4 {
    font-weight: 600 !important;
    font-size: 1em !important;
    line-height: 1.333em !important;
    /*24px*/
    letter-spacing: -0.005em !important;
  }
  h5 {
    font-weight: 600 !important;
    font-size: 0.875em !important;
    line-height: 1.125em !important;
    /*18px*/
    letter-spacing: -0.005em !important;
  }
  ol ol {
    list-style: lower-alpha;
  }
  ol ol ol {
    list-style: lower-roman;
  }
  ul,
  ol {
    margin-left: 20px;
  }
  .container.main {
    padding: 0px 28px;
    padding-bottom: 24px;
  }
  .form-group label {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #999999;
    font-weight: 300;
    margin-bottom: 5px;
  }
  .NotCandidate {
    text-align: center;
    margin-top: 45px;
  }
  .NotCandidate a {
    color: #F96302;
  }
  .desc {
    margin-bottom: 18px;
  }
  .navHeader div:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  .mainNav {
    text-align: left;
    color: #EEEEEE;
    display: flex;
    align-items: center;
    margin: 0px -15px;
    background-color: #2B3134;
  }
  .nav {
    font-size: 20px;
    text-align: left;
  }
  .navImg {
    width: 50px;
    margin: 12px;
    float: left;
  }
  .navTitle {
    float: left;
    margin-left: 0px;
    margin-right:0px;
    font-size: 20px;
    margin-top: 24px;
  }
  .heading {
    margin-top: 12px;
  }
  .primary.button.submitbutton,
  .button.submitbutton {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
  input[type="text"],
  input[type="textarea"] {
    outline: none;
  }
  input[type="text"]:focus,
  input[type="textarea"]:focus {
    outline: none;
    background-color: #fff;
    -webkit-box-shadow: 0 0 5px #00ae4d;
    -moz-box-shadow: 0 0 5px #00ae4d;
    -ms-box-shadow: 0 0 5px #00ae4d;
    box-shadow: 0 0 5px #00ae4d;
    border: 1px solid #00ae4d;
    margin: 0px;
  }
  div.messageFormat {
    margin-top: 25px;
    font-weight: 500;
    /*padding-top: 15px;*/
    font-size: 16px;
    line-height: 28px;
    color: #444444;
  }
  blockquote {
    font-weight: 400italic;
    font-style: italic;
    font-size: 1.125em;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    padding: 20px;
  }
  p.terms {
    background-color: #fff8e5;
    font-size: 0.875em;
    line-height: 1.667em;
    /*20px*/
    padding: 20px;
  }
  p.legal {
    color: #666666;
    font-size: 0.875em;
    line-height: 1.667em;
    /*20px*/
  }
  .ssnTb {
    width: 100%;
  }
  .ssnCol {
    margin-bottom: 18px;
  }
  .row.no-pad {
    margin-right: -15px;
    margin-left: -15px;
    padding: 0px;
  }
  .row.no-pad>[class*='col-'] {
    padding-right: 0px;
    padding-left: 0px;
  }
  .banner {
    font-size: 16px;
    background-color: #FEF2F2;
    color: #ED1C24;
    line-height: 24px;
    padding: 9px;
    border: 1px solid;
    width: 100vw;
    position: absolute;
    left:0px;
    top: 73px;
    z-index: 1;
    display: flex;
    text-align: center;
    align-items: center;

  }
  .banner span {
    vertical-align: middle;
    padding-right: 10px;
  }
  .banner .glyphicon-ban-circle {
    font-size: 28px;
    width: 10%;
    float: left;
    text-align: center;
  }
  .banner .glyphicon-remove {
    font-size: 16px;
    width: 20px;
    float: right;
  }
  .banner .glyphicon-class {
    width: 80%;
  }
  .gutter-left {
    margin-left: 0px;
  }
  .gutter-right {
    margin-right: 6px;
  }
  .interestedInPositionMessage {
    margin-top: 15px;
    font-size: 18px;
    color: #666666;
    font-weight: 500;
    line-height: 28px;
  }
  .stillInterestedQuestion {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    color: #666666;
    font-weight: 600;
    line-height: 28px;
  }
  .stillInterested.button {
    width: 50%;
    margin: 0px;
    padding: 0px;
  }
  .interested {
    padding-left: 0px;
    padding-right: 0px;
  }
  .CalendarDaySlotsWrapper {
    background-color: #EBEBEB;
    padding: 0px 7.5px;
    margin: 0px -30px;
    border-bottom: white 15px solid;
    border-top: white 15px solid;
  }
  .CalendarDaySlot {
    background-color: #EBEBEB;
    margin: 0px;
  }
  .CalendarDaySlotHeading {
    background-color: #EBEBEB;
    margin: 0px;
  }
  .CalendarDaySlotHeading H3 {
    text-align: center;
    color: grey;
    font-size: 14px;
    margin: 24px 0px;
  }
  .CalendarDaySlot div {
    padding: 0 7.5px;
  }
  .CalendarDaySlot div.timeSlot {
    background-color: #FBFBFB;
    color: #E08E4E;
    text-align: center;
    padding: 8px 0px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .CalendarDaySlot div.selectedSlot {
    background-color: #f96302;
    color: #FBFBFB;
    text-align: center;
    padding: 8px 0px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .largeVerticalMargins {
    margin: 25px 0px;
  }
  .confirmationForm {
    margin: 25px 0px;
  }
  .confirmationForm .col-3 {
    color: #999999;
    font-size: 14px;
  }
  .confirmationForm .col-9 {
    font-size: 18px;
    font-weight: bold;
  }
  .confirmationForm .row {
    margin-bottom: 18px;
  }
  .noBottomMargin {
    margin-bottom: 0px;
  }
  .messageFormat h2 {
    margin-top: 0px;
  }
  .messageFormat h2,
  .messageFormat h3 {
    margin-bottom: 18px;
  }
  #fields {
    margin-bottom: 12px;
  }
  .jobPreview div:nth-child(1) div {
    color: #666666;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .jobPreview div:nth-child(2) div {
    color: #444444;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  .jobPreview div:nth-child(3) div, .questions .subtext{
    color: #666666;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .primary.button.yesno,
  .button.yesno {
    margin: 0px;
    padding: 0px;
    width: 50%;
  }
   .questionTop{
    color: #444444;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 10px;
    margin-top:24px;
   }
    .questionBody{
    color: #444444;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 24px;
    margin-top:10px;
   }
    .questionText{
    color: #444444;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 24px;
    margin-top:24px;
   }
   .questionTopMargin
   {
     margin-top: 24px;
   }
   .questionBotMargin
   {
     margin-bottom:24px;
   }
   .bottomButtonBuffer
   {
      margin-bottom: 20px;
   }
   .bottomButton
   {
     width: 100%;
     /*position: absolute;*/
     bottom: 10px;
     left: 14px;
     float: right;
   }

  .declineBtn
  {
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
  }

  button.declineBtn{
    font-size: 1.1em;
  }

  .declineListMargin{
    margin-right: 20px;
  }

  .largeViewBreak{
    display: none;
  }

  .modal-buttons{
    position: absolute;
    bottom:32px;
    width: 100%
  }

  .modal-dialog {
    margin-top: 100px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    width: auto !important;
  }

  .semibold-text {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
  }

}


/*Small devices (landscape phones, 576px and up) 
@media (min-width: 576px) and (max-width: 767px) { 
  body #root {
    font-size:1.1em;
  }
 }*/


/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {

  .questionHeader{
    margin-top: 15px;
  }
  .stillInterestedQuestion {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    font-size: 18px !important;
    color: #666666 !important;
    font-weight: 600 !important;
    line-height: 28px !important;
  }
  .form-group label {
    font-weight: 700;
    margin-bottom: 5px
  }
  body {
    font-family: Open Sans, sans-serif;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    line-height: 1.500em !important;
    color: #444444 !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    -ms-font-smoothing: antialiased !important;
    background-color: #D6D6D6 !important;
  }
  h2 {
    color: #666666 !important;
    font-size: 45px !important;
    font-weight: 300 !important;

    /*30px*/
  }
  p {
    margin-bottom: 18px !important;
  }
  .container.main {
    width: 750px;
    background-color: #fff;
    padding-bottom: 24px;
    margin-top: 36px;
  }
  .mainNav {
    text-align: left;
    color: #EEEEEE;
    display: flex;
    align-items: center;
    margin: 0px -15px;
    background-color: #2B3134;
  }
  .row.no-pad {
    margin-right: -15px;
    margin-left: -15px;
    padding: 0px;
  }
  .row.no-pad>[class*='col-'] {
    padding-right: 0px;
    padding-left: 0px;
  }
  .navImg {
    width: 50px;
    margin: 12px;
    float: left;
  }
  .navTitle {
    float: left;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 20px;
    margin-top: 24px;
  }
  .container {
    width: 100%;
  }
  .CalendarDaySlotsWrapper {
    background-color: #EBEBEB;
    padding: 0px 7.5px;
    margin: 0px;
    border-bottom: white 15px solid;
    border-top: white 15px solid ;

  }
  .CalendarDaySlot {
    background-color: #EBEBEB;
    margin: 0px;
  }
  .CalendarDaySlotHeading {
    background-color: #EBEBEB;
    margin: 0px;
  }
  .CalendarDaySlotHeading H3 {
    text-align: center;
    color: grey;
    font-size: 14px;
    margin: 24px 0px;
  }
  .CalendarDaySlot div {
    padding: 0 7.5px;
  }
  .CalendarDaySlot div.timeSlot {
    background-color: #FBFBFB;
    color: #E08E4E;
    text-align: center;
    padding: 12px 0px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .CalendarDaySlot div.selectedSlot {
    background-color: #f96302;
    color: #FBFBFB;
    text-align: center;
    padding: 12px 0px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .NotCandidate a {
    color: #F96302;
  }
  .confirmationForm {
    margin-bottom: 24px;
  }
  .bigScreenVerticalMargins{
    margin: 40px 0px;
  }
  .cancelBtn{
    margin-left: -1.5em;
    padding-left: 0em;
    width: 120px;
    float: right;
    top: 50%;
  }
  .banner {
    font-size: 16px;
    background-color: #FEF2F2;
    color: #ED1C24;
    line-height: 24px;
    padding: 9px;
    border: 1px solid;
    width: 100vw;
    position: absolute;
    left:0px;
    top: 73px;
    z-index: 1;
    display: flex;
    text-align: center;
    align-items: center;
  }
  .banner span {
    vertical-align: middle;
    padding-right: 10px;
  }
  .banner .glyphicon-ban-circle {
    font-size: 28px;
    width: 10%;
    float: left;
    text-align: center;
  }
  .banner .glyphicon-remove {
    /*font-size: 16px;*/
    /*float: right;*/
    /*vertical-align: center;*/
    /*width: auto;*/
    /*right: 10px;*/
    font-size: 16px;
    vertical-align: 'top';
    width: 20px;
    float: right;
  }
  .banner .glyphicon-class {
    width: 80%;
  }

  .pushLeft{
    margin-left: -25px;
  }

  .confirmationForm .col-3 {
    color: #999999;
  }

  .confirmationForm .col-9 {
    font-size: 18px;
    font-weight: bold;
  }

  .confirmationForm .row {
    margin-bottom: 18px;
  }

  .declineBtn
  {
    margin-bottom: 10px;
    margin-top: 10px;
    width: 225px;
  }

  .buttonRightAlign{
    margin-left: 54px;
  }

  .selectTimeBtn{
    margin: -10px;
  }

  .button.nli {
    font-size: 1em;
    line-height: 20px;
    height: 46px;
    width:162px;
    padding: 0px 20px;
    margin-left: 70px;
  }
  .button.reschedule {
    margin-left: 110px;
    width: 190px
  }

  .modal-buttons {
    margin-top: 130px;
  }

  .button.submitbutton.bsizeSmall.bottom-right{
    margin-left:94px;
  }

  .modal-dialog {
    margin: 100px auto !important;
    width: 450px !important;
  }

  .loader {
    top: 25px;
  }
  .bottomButtonBuffer
  {
    margin-bottom: 20px;
  }

  .semibold-text {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
  }

}


/* Large devices (desktops, 992px and up) 
@media (min-width: 992px) and (max-width: 1199px) { 
  body #root {
    font-size:1.3em;
  }
 }

/* Extra large devices (large desktops, 1200px and up) 
@media (min-width: 1200px) { 
  body #root {
    font-size:1.4em;
  }
 }
 */
.semibold-text {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.paddedForm .col-sm-12{
  padding-bottom: 24px;
}

.buttonSpacing {
  margin-top: 12px;
}

.button,
.button:visited,
.button.primary,
.primary.button:visited,
.button.tertiary,
.button.tertiary:visited {
  font-size: 1.125em;
  line-height: 46px;
  height: 46px;
  padding: 0px 20px;
}

.button.md,
.button.primary.md,
.button.tertiary.md {
  font-size: 1em;
  line-height: 38px;
  height: 38px;
  padding: 0 15px;
}

.button.sm,
.button.primary.sm,
.button.tertiary.sm {
  font-size: 0.875em;
  line-height: 30px;
  height: 30px;
  padding: 0 12px;
}


/*PRIMARY BUTTON*/

.button.primary,
.primary.button:visited {
  background-color: #f96302 !important;
  color: #ffffff !important;
}

.button.primary:hover,
.stepper .button.primary.step:active,
.button.primary:focus {
  background-color: #fd751c !important;
  color: #ffffff !important;
}

.button.primary:active {
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.35) !important;
  background-color: #db5702 !important;
}

.button.primary:disabled,
.button.primary.disabled {
  color: #999999 !important;
  background-color: #cccccc !important;
  box-shadow: none;
  cursor: not-allowed;
}


/*SECONDARY BUTTON*/

.button,
.button:visited {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.24);
  /*text-transform: capitalize;*/
  display: inline-block;
  color: #f96302;
  background-color: #fbfbfb;
  text-decoration: none;
  border: none;
  border-radius: 2px;
  font-weight: 600;
  /*Open Sans Semi-Bold 600*/
  text-align: center;
  outline: none;
  margin: 10px 10px;
  letter-spacing: 0px;
  cursor: pointer;
}

.button:hover,
.stepper .button.step:active,
.button:focus {
  background-color: #ffffff !important;
  color: #f96302 !important;
  text-decoration: none;
}

.button:active {
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.35) !important;
  background-color: #eeeeee !important;
  border: 0px solid #ffffff;
}

.button:disabled,
.button.disabled {
  box-shadow: none !important;
  color: #999999 !important;
  background-color: #f5f5f5 !important;
  cursor: not-allowed;
}

.button.icon {
  min-width: 46px;
  min-height: 46px;
  padding: 0;
  font-size: 24px;
}


/*TERTIARY BUTTON*/

.button.tertiary,
.button.tertiary:visited {
  background-color: transparent;
  /*color: #f96302;*/
  outline: 2px lightgrey solid;
  color: #3b3a30;
  box-shadow: none;
}

/*.button.tertiary:hover,*/
/*.stepper .button.tertiary.step:active,*/
/*.button.tertiary:focus {*/
/*  background-color: rgba(0, 0, 0, 0.04) !important;*/
/*  color: #f96302 !important;*/
/*}*/

.button.tertiary:active {
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.35) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.button.tertiary:disabled,
.button.tertiary.disabled {
  color: #999999 !important;
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none;
  cursor: not-allowed;
}

.bsize {
  width: 225px;
}

.bsizeSmall {
  width:200px;
}

.bsizeBig {
  width: 300px;
}
/*color: #666666;	font-family: "Open Sans";	font-size: 45px;	font-weight: 300;*/

.formErrorMessage {
  color: red;
  font-size: .7em;
}

.pageErrorMessage {
  color: red;
  margin-top: 12px;
}

input[type="text"].validationError {
  outline: none;
  background-color: #fff;
  -webkit-box-shadow: 0 0 5px red;
  -moz-box-shadow: 0 0 5px red;
  -ms-box-shadow: 0 0 5px red;
  box-shadow: 0 0 5px red;
  border: 1px solid red;
  margin: 0px;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #f96302;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.orangeText {
  color: #f96302;
}
ul.bulletList > li:before {
}
ul.bulletList {
  list-style: disc;
  padding-left: .8em;
  margin-left: 0.2em;
  font-weight: bold;
}
.padLeft {
  padding-left: 2em;
}
.loader:before,
.loader:after {
  position: absolute;
  content: '';
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #ffffff;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.2em 5.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #ffffff;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  -webkit-transform-origin: 0px 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
.topMargin{
  margin-top: 1.2em;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.aspect-ratio {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* The height of the item will now be 56.25% of the width. */
  margin-top:23px;
}

/* Adjust the iframe so it's rendered in the outer-width and outer-height of it's parent */
.aspect-ratio iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.b-contain *, .b-contain *::before, .b-contain *::after {
  box-sizing: content-box !important;
}

.b-contain input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.b-contain span {
  line-height: 1.54;
  /*font-size: 1rem;*/
  font-family: inherit;
}

.b-contain {
  display: table;
  position: relative;
  padding-left: 1.8rem;
  cursor: pointer;
  margin-bottom: -0.8rem;
}

.b-contain input[type="checkbox"] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background: rgba(241, 245, 248, 1);
  transition: background 250ms;
  border: 1px solid rgba(184, 194, 204, 1);
  border-radius: 0.125rem;
}



.b-contain input[type="checkbox"] ~ .b-input::after {
  content: '';
  position: absolute;
  display: none;
  left: .4rem;
  top: .1rem;
  width: .3rem;
  height: .69rem;
  border: solid rgba(255, 255, 255, 1);
  border-width: 0 2px 2px 0;
  transition: background 250ms;
  transform: rotate(45deg);
}


.b-contain input:disabled ~ .b-input::after {
  border-color: rgba(135, 149, 161, 1);
}

.b-contain input:checked ~ .b-input::after {
  display: block;
}

/*.b-contain:hover input ~ .b-input,*/
/*.b-contain input:focus ~ .b-input {*/
/*  background: rgb(0,176,76);*/
/*}*/

.b-contain input:focus ~ .b-input {
  box-shadow: 0 0 0 2px rgba(52,144,220,0.5);
}

.b-contain input:checked ~ .b-input {
  background: rgb(0,176,76);
  border-color: rgb(0,176,76);
}

.b-contain input[type="checkbox"]:disabled ~ .b-input {
  background: rgba(241, 245, 248, 1);
  border-color: rgba(184, 194, 204, 1);
  opacity: 0.6;
  cursor: not-allowed;
}


.b-contain input:checked:focus ~ .b-input, .b-contain:hover input:not([disabled]):checked ~ .b-input {
  background: rgba(1, 175, 78, 1);
  border-color: rgba(13, 143, 255, 1);
}

.b-contain .b-input::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  margin-left: -0.85rem;
  margin-top: -0.85rem;
  background: rgba(0, 130, 243, 1);
  border-radius: 2rem;
  opacity: .6;
  z-index: 99999;
  transform: scale(0);
}

@keyframes b-ripple {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes b-ripple-duplicate {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.b-contain input + .b-input::before {
  animation: b-ripple 250ms ease-out;
}

.b-contain input:checked + .b-input::before {
  animation-name: b-ripple-duplicate;
}

.b-contain .b-input::before {
  visibility: hidden;
}

.b-contain input:focus + .b-input::before {
  visibility: visible;
}

.b-contain:first-child .b-input::before {
  visibility: hidden;
}

.checkboxLabel {
  margin-left: 2.6rem;
}

.footer {
  font-size: 18px;
}

.moreButton {
  margin-top:1.125em;
  font-family: "Open Sans", sans-serif;
  font-size: 1.125em;
  font-weight: bold;
  color: #f96302;
  cursor: pointer;
  margin-bottom: 1em;
}

.extraVerbiage {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  margin-bottom: 15px;
}
a {
  color: #f96302!important;
  text-decoration: underline!important;
}


a:hover {
  color: #E08E4E!important;
  cursor: pointer!important;
}

/*Added for CSS auth JR changes*/
.aLinkAuth {
  color: #0069D9!important;
  text-decoration: none!important;
  font-size: large;
}

.pull-bottom-right {
  position:absolute;
  bottom:20px;
  right:20px;
}
.x-close {
  color: #f96302;
  position:absolute;
  top:15px;
  right:15px;
  font-size: 20px;
  cursor: pointer;
  padding-right: 0px;
  padding-left: 0px;
}

.overlay{
  position: fixed;
  inset: 0px;
  background-color: #333333a1;
}

.popupHeader{
  padding-top: 0px;
  margin-top: 0px;
  padding-right:40px;
}

.d-block {
  display: block!important;
  margin-left: 20px;
}

.d-none {
  display: none!important;
}
.d-grid {
  display: grid!important;
  margin-left: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba(0, 0, 0, 0.6);
}

.modal-container{
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  height: 400px;
  margin-top:100px;
  margin-left: auto;
  margin-right: auto;
  max-width:686px;
}

.aslLink {
  text-align:center
}

.questionHeader{
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight:bold;
  margin-bottom: 12.5px;
  margin-top:15px;
}

.questionBody {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin-top: 12.5px;
  margin-bottom: 12.5px;
}
.minimumAge div:nth-child(1) div {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight:bold;
  margin-bottom: 12.5px;
  margin-top: 15px;
}
.minimumAge div:nth-child(2) div {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin-top: 12.5px;
  margin-bottom: 12.5px;
}

.scheduleSlot {
  font-weight: 600;
  font-size: 1.125em;
  letter-spacing: -0.010em;
  line-height: 1.333em;
  border: solid #cccccc;
  margin-top: 7px;
  margin-bottom: 7px;
  padding-top: 10px;
  padding-bottom: 15px;
  color: #cccccc;
}

.scheduleSlot .row {
  margin-left: 5px;
  margin-right: 5px;
}

.activeSlot {
  color: #2B3134;
}

.scheduleSlot .shiftSelector {
  border: solid darkgray;
  margin-top: 15px;
  margin-left:20px;
  margin-right: 20px;
  border-radius: 3px;
}

.shiftAnswer {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

/*.shiftAnswer:first-child {*/
/*  border-right: solid #cccccc;*/
/*}*/

.shiftBorder {
  border-left: 1px solid darkgray;
  position: absolute;
  top: 4px;
  bottom: 4px;
  left:-0.5px;
}

.shiftSelected {
  background-color: #f96302;
  color: rgb(255, 255, 255);
}

.overNight {
  background-color: #2B3134;
  color: white;
  margin-left: auto;
  margin-right: 0;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 12px;
  font-size: 14px;
  width: 84px;
  margin-top:3px;
}

header {
  position: fixed;
  top: 0;
}

.modal-header {
  background-color: #FFFBE6;
}



.modal-body {
  padding: 20px !important;
  padding-bottom: 4px !important;
}

.info-circle {
  color: #FAAD14 !important
}

.modal-footer {
  border-top: 0px !important;
  padding-bottom: 0px;
  padding-right: 0px;
}

.infoHeader {
  margin-bottom: 20px !important;
}
/*.scheduleSlot .shiftTitle {*/
/*  margin-left:5px;*/
/*  margin-right:5px;*/
/*}*/

/*Below has been added to make some bootstrap 4 formatting to be like bootstrap 3*/
h2 {
  margin-top: 1.25rem !important;
  margin-bottom: 0.625rem !important;
}

.container {
  max-width: 100%;
}

.banner.anticon-stop {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  width: 30px;
  height: 30px;
  stroke-width: 25px;
  stroke: red;
  color: red;
}

.form-control {
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  height: 34px;
  padding: 6px 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: 100%;
}

.form-control::placeholder {
  color: #999999;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}


